export const environment = {
    development: {
        apiKey: "AIzaSyDyagArXeKVyQ_6qZZvJOVK5UUYs2esvW4",
        authDomain: "floorball-dev-447ff.firebaseapp.com",
        databaseURL: "https://floorball-dev-447ff-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "floorball-dev-447ff",
        storageBucket: "floorball-dev-447ff.appspot.com",
        messagingSenderId: "435384250401",
        appId: "1:435384250401:web:739a6e4f8b6b73d614b360",
        measurementId: "G-9FFREZZD3S"
    },
    production: {
        apiKey: "AIzaSyAy29_IMomdhdo6T78SRkuuUipaZDlQOMc",
        authDomain: "floorball-prod.firebaseapp.com",
        databaseURL: "https://floorball-prod.firebaseio.com",
        projectId: "floorball-prod",
        storageBucket: "floorball-prod.appspot.com",
        messagingSenderId: "143696099640",
        appId: "1:143696099640:web:20cb05ab8edd4b731fa9cd",
        measurementId: "G-W6QDWJDBS2"
    }
}