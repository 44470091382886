export const intl = {
  'en': {
    'Clubs.club': 'Club',
    'Clubs.readMore': 'Read more...',
    'Clubs.fullName': 'The full name of the club',
    'Clubs.fullNameInt': 'The full name of the club (international version)',
    'Clubs.shortName': 'The short name of the club',
    'Clubs.shortNameInt': 'The short name of the club (international version)',
    'Clubs.est': 'Est.',
    'Clubs.contactPhone': 'Contact phone',
    'Clubs.email': 'E-mail',
    'Clubs.address': 'Address',
    'Clubs.players': 'Players of the club',
    'Clubs.url': 'URL',
    'Clubs.line': 'Line',
    'Clubs.city': 'City',
    'Clubs.country': 'Country',
    'Clubs.region': 'Region',
    'Clubs.postCode': 'Postal code',
    'Clubs.founded': 'Founded',
    'Clubs.added': 'Added',
  },
  'uk': {
    'Clubs.club': 'Клуб',
    'Clubs.readMore': 'Докладніше...',
    'Clubs.fullName': 'Повна назва клубу',
    'Clubs.fullNameInt': 'Повна назва клубу (international version)',
    'Clubs.shortName': 'Скорочена назва клубу',
    'Clubs.shortNameInt': 'Скорочена назва клубу (international version)',
    'Clubs.est': 'Рік заснування',
    'Clubs.contactPhone': 'Контактний телефон',
    'Clubs.email': 'Електрона пошта',
    'Clubs.address': 'Адреса',
    'Clubs.players': 'Гравці клубу',
    'Clubs.url': 'URL',
    'Clubs.line': 'Вулиця',
    'Clubs.city': 'Місто',
    'Clubs.country': 'Країна',
    'Clubs.region': 'Регіон',
    'Clubs.postCode': 'Поштовий індекс',
    'Clubs.founded': 'Заснований',
    'Clubs.added': 'Доданий',
  },
};
