export const intl = {
  'en': {
    'Players.list.title': 'Players list',
    'Players.items': 'Players in list:',
    'Players.filters': 'Filters',
    'Players.enterName': 'Enter some name',
    'Players.name': 'Name',
    'Players.nameEN': 'Name latin',
    'Players.enterNumber': 'Enter some license number',
    'Players.enterClub': 'Club',
    'Players.removePhoto': 'Remove photo from card',
    'Players.filter.unactive': 'Show expired license',
    'Players.filter.all': 'All',
    'Players.height.header': 'Height, sm',
    'Players.height.num': '{{height}} sm',
    'Players.weight.header': 'Weight, kg',
    'Players.weight.num': '{{weight}} kg',
    'Players.license.header': 'License',
    'Players.license.number': '#',
    'Players.license.type': 'License type',
    'Players.license.taxnumber': 'Tax number',
    'Players.license.SENIOR': 'Senior',
    'Players.license.JUNIOR': 'Junior',
    'Players.license.KID': 'Kid',
    'Players.license.AMATEUR': 'Amateur',
    'Players.license.Adult_A': 'Adult A',
    'Players.license.Adult_B': 'Adult B',
    'Players.license.Junior_A': 'Junior A',
    'Players.license.Junior_B': 'Junior B',
    'Players.position.header': 'Position',
    'Players.position.FORWARD': 'Forward',
    'Players.position.DEFENDER': 'Defender',
    'Players.position.GOALIE': 'Goalie',
    'Players.side.header': 'Stick side',
    'Players.side.R': 'Right',
    'Players.side.L': 'Left',
    'Players.gender.header': 'Gender',
    'Players.gender.MALE': 'Male',
    'Players.gender.FEMALE': 'Female',
    'Players.endActivationDate.label': 'License exp. date',
    'Players.lastName.label': 'Last Name',
    'Players.lastName.placeholder': 'Last Name',
    'Players.firstName.label': 'First Name',
    'Players.firstName.placeholder': 'First Name',
    'Players.secondName.label': 'Second Name',
    'Players.secondName.placeholder': 'Second Name',
    'Players.lastNameInt.label': 'Second name English',
    'Players.lastNameInt.placeholder': 'Second name English',
    'Players.firstNameInt.label': 'First name English',
    'Players.firstNameint.placeholder': 'First name English',
    'Players.citizenship.label': 'Citizenship',
    'Players.citizenship.placeholder': 'Citizenship',
    'Players.born.label': 'Born',
    'Players.registrDate': 'Register date in the system',
    'Players.table.license': 'License',
    'Players.table.photo': 'Photo',
    'Players.table.firstName': 'First Name',
    'Players.table.lastName': 'Last Name',
    'Players.table.age': 'Age',
    'Players.table.club': 'Club',
    'Players.homeClub': 'Home Club',
    'Players.currentClub': 'Current Club',
    'Players.transfersAndLoans': 'Transfers and Loans',
    'Players.from': '→',
    'Players.to': '←',
    'Players.at': '→',
    'Players.firstClub': 'First Club',
    'Players.uniqueExternId': 'Unique external ID (tax number)',
    'Players.autocomplite': 'Player',
    'Players.headtitle': 'Player: {{name}}',
  },
  'uk': {
    'Players.list.title': 'Гравці',
    'Players.items': 'Гравців в списку:',
    'Players.filters': 'Фільтри',
    'Players.enterName': 'Введіть ім\'я',
    'Players.name': 'Ім\'я',
    'Players.nameEN': 'Ім\'я латиницею',
    'Players.enterNumber': 'Введіть номер ліцензії',
    'Players.enterClub': 'Клуб',
    'Players.removePhoto': 'Видалити фото з картки',
    'Players.filter.unactive': 'Показувати неактивні ліцензії',
    'Players.filter.all': 'Всі',
    'Players.height.header': 'Зріст, см',
    'Players.height.num': '{{height}} см',
    'Players.weight.header': 'Вага, кг',
    'Players.weight.num': '{{weight}} кг',
    'Players.license.header': 'Ліцензія',
    'Players.license.number': '№',
    'Players.license.type': 'Тип ліцензії',
    'Players.license.taxnumber': 'ІНН',
    'Players.license.SENIOR': 'Доросла',
    'Players.license.JUNIOR': 'Юніорська',
    'Players.license.KID': 'Юнацька',
    'Players.license.AMATEUR': 'Аматорська',
    'Players.license.Adult_A': 'Доросла A',
    'Players.license.Adult_B': 'Доросла B',
    'Players.license.Adult_M': 'Доросла М',
    'Players.license.Junior_A': 'Юніорська A',
    'Players.license.Junior_B': 'Юніорська B',
    'Players.position.header': 'Позиція',
    'Players.position.FORWARD': 'Нападник',
    'Players.position.DEFENDER': 'Захисник',
    'Players.position.GOALIE': 'Воротар',
    'Players.side.header': 'Сторона ключки',
    'Players.side.R': 'Права',
    'Players.side.L': 'Ліва',
    'Players.gender.header': 'Стать',
    'Players.gender.MALE': 'Чоловік',
    'Players.gender.FEMALE': 'Жінка',
    'Players.endActivationDate.label': 'Дата закінчення ліцензії',
    'Players.lastName.label': 'Прізвище',
    'Players.lastName.placeholder': 'Прізвище',
    'Players.firstName.label': 'Ім\'я',
    'Players.firstName.placeholder': 'Ім\'я',
    'Players.secondName.label': 'По батькові',
    'Players.secondName.placeholder': 'По батькові',
    'Players.lastNameInt.label': 'Прізвище латиницею',
    'Players.lastNameInt.placeholder': 'Прізвище латиницею',
    'Players.firstNameInt.label': 'Ім\'я латиницею',
    'Players.firstNameInt.placeholder': 'Ім\'я латиницею',
    'Players.citizenship.label': 'Громадянство',
    'Players.citizenship.placeholder': 'Громадянство',
    'Players.born.label': 'Дата народження',
    'Players.registrDate': 'Дата реєстрації в системі',
    'Players.table.license': 'Ліцензія',
    'Players.table.photo': 'Фото',
    'Players.table.firstName': 'Ім\'я',
    'Players.table.lastName': 'Прізвище',
    'Players.table.age': 'Повний вік',
    'Players.table.club': 'Клуб',
    'Players.homeClub': 'Перший клуб',
    'Players.currentClub': 'Теперішній клуб',
    'Players.transfersAndLoans': 'Трансфери та оренди',
    'Players.from': '→',
    'Players.to': '←',
    'Players.at': '→',
    'Players.firstClub': 'Перший клуб',
    'Players.uniqueExternId': 'Унікальний зовнішній IД (IНН)',
    'Players.autocomplite': 'Гравець',
    'Players.headtitle': 'Гравець: {{name}}',
  },
};
