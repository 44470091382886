import React from 'react';

export default function Info() {


  return (
    <div>
      <h1>Info</h1>
    </div>
  );
}